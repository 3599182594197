<template>
  <div>
    <list
      ref="refClassContractList"
      sort-by="ClaseContratoId"
      :refetch-records="fetchClassContracts"
      refetch-records-name="clasesContrato"
      :actions="actions"
      key-field="claseContratoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteClassContract"
      :filters.sync="filters"
    />
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refClassContractList = ref(null)
    /* Services */
    const {
      fetchClassContracts,
      deleteClassContract,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.classContract.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.classContract.columns.description'),
        key: 'descripcion',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'ClaseContrato',
        routeName: 'apps-human-resources-class-contract-edit',
        params: ['claseContratoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'ClaseContrato',
        params: ['claseContratoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-class-contract-add',
        label: i18n.t('Routes.ClassContractAdd'),
        aclAction: 'create',
        aclResource: 'ClaseContrato',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refClassContractList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchClassContracts,
      deleteClassContract,
    }
  },
}
</script>
